import React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/app/docs/node_modules/@uchiru/gatsby-theme-doctocat/src/components/layout.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h2>{`Installation`}</h2>
    <p>{`To get started using Uchiru Components, install the package and its peer dependencies with your package manager of choice:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-bash"
      }}>{`# with npm
npm install @uchiru/components react react-dom styled-components

# with yarn
yarn add @uchiru/components react react-dom styled-components
`}</code></pre>
    <p>{`You can now import Uchiru Components from the main package module:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-javascript"
      }}>{`// using import syntax
import {Box, Flex} from '@uchiru/components'

// using require syntax
const {Box, Flex} = require('@uchiru/components')
`}</code></pre>
    <h3>{`Minimizing bundle size`}</h3>
    <p>{`Module bundlers that use ECMAScript modules (ESM) will automatically tree-shake Uchiru Components, ensuring that no unused code is included in your final bundle. However, if you're not using ESM, you may be able to drastically reduce the size of your final bundle by importing components individually from the `}<inlineCode parentName="p">{`lib`}</inlineCode>{` subfolder:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-javascript"
      }}>{`// using import syntax
import Box from '@uchiru/components/lib/Box'
import Flex from '@uchiru/components/lib/Flex'

// using require syntax
const Box = require('@uchiru/components/lib/Box')
const Flex = require('@uchiru/components/lib/Flex')
`}</code></pre>
    <p>{`Note that the modules in the `}<inlineCode parentName="p">{`lib`}</inlineCode>{` folder are CommonJS-style modules; if you're using ESM and a compatible module bundler, importing files individually from `}<inlineCode parentName="p">{`lib`}</inlineCode>{` provides no benefit.`}</p>
    <h3>{`Peer dependencies`}</h3>
    <p>{`Uchiru Components ships with a few libraries labeled as peer dependencies. These libraries are separated because they are commonly already installed in the host project and having multiple versions can introduce errors.`}</p>
    <p>{`Uchiru Components requires the following libraries to be installed along with it:`}</p>
    <ul>
      <li parentName="ul"><inlineCode parentName="li">{`styled-components`}</inlineCode>{` at version 4.0.0 or higher`}</li>
      <li parentName="ul"><inlineCode parentName="li">{`react`}</inlineCode>{` at versions 16.8.0 or higher`}</li>
      <li parentName="ul"><inlineCode parentName="li">{`react-dom`}</inlineCode>{` at versions 16.8.0 or higher`}</li>
    </ul>
    <h2>{`BaseStyles`}</h2>
    <p>{`In order to set baseline color, font-family, and line-heights across your project, you will need to establish base Uchiru styles for your app by wrapping all of your Uchiru components in `}<inlineCode parentName="p">{`<BaseStyles>`}</inlineCode>{` at the root of your app:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-jsx"
      }}>{`import {BaseStyles, Box, Heading} from '@uchiru/components'

export default const MyApp = () => (
  <BaseStyles>
    <Box m={4}>
      <Heading mb={2}>Hello, world!</Heading>
      <p>This will get Uchiru text styles.</p>
    </Box>
  </BaseStyles>
)
`}</code></pre>
    <p>{`This will apply the same `}<inlineCode parentName="p">{`color`}</inlineCode>{`, `}<inlineCode parentName="p">{`font-family`}</inlineCode>{`, and `}<inlineCode parentName="p">{`line-height`}</inlineCode>{` styles to the `}<inlineCode parentName="p">{`<body>`}</inlineCode>{` as `}<a parentName="p" {...{
        "href": "https://github.com/uchiru/css/blob/master/src/base/base.scss#L15-L20"
      }}>{`Uchiru CSS's base styles`}</a>{`.`}</p>
    <h2>{`Theming`}</h2>
    <p>{`Components are styled using Uchiru's `}<a parentName="p" {...{
        "href": "https://github.com/uchiru/components/blob/master/src/theme-preval.js"
      }}>{`theme`}</a>{` by default, but you can provide your own theme by using `}<a parentName="p" {...{
        "href": "https://styled-components.com/"
      }}>{`styled-component's`}</a>{` `}<inlineCode parentName="p">{`<ThemeProvider>`}</inlineCode>{`. If you'd like to fully replace the Uchiru `}<a parentName="p" {...{
        "href": "https://github.com/uchiru/components/blob/master/src/theme-preval.js"
      }}>{`theme`}</a>{` with your custom theme, pass your theme to the `}<inlineCode parentName="p">{`<ThemeProvider>`}</inlineCode>{` in the root of your application like so:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-jsx"
      }}>{`import {ThemeProvider} from 'styled-components'

const theme = { ... }

const App = (props) => {
  return (
    <div>
      <ThemeProvider theme={theme}>
        <div>your app here</div>
      </ThemeProvider>
    </div>
  )
}
`}</code></pre>
    <p>{`If you'd like to merge the Uchiru theme with your theme, you can do so by importing the Uchiru theme and then merging the themes using a library like `}<a parentName="p" {...{
        "href": "https://www.npmjs.com/package/deepmerge"
      }}>{`deepmerge`}</a>{`:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-jsx"
      }}>{`import {ThemeProvider} from 'styled-components'
import {theme} from '@uchiru/components'
import deepmerge from 'deepmerge'

const customTheme = { ... }
const newTheme = deepmerge(theme, customTheme, {
  // overwrite arrays instead of concatenating
  arrayMerge: (_dest, source) => source
})


const App = (props) => {
  return (
    <div>
      <ThemeProvider theme={deepmerge(theme, )}>
        <div>your app here</div>
      </ThemeProvider>
    </div>
  )
}
`}</code></pre>
    <p>{`Note that using `}<inlineCode parentName="p">{`Object.assign`}</inlineCode>{` to merge themes will only create a shallow merge. This means that if both themes have a `}<inlineCode parentName="p">{`color`}</inlineCode>{` object, the `}<em parentName="p">{`entire`}</em>{` `}<inlineCode parentName="p">{`color`}</inlineCode>{` object will be replaced with the new `}<inlineCode parentName="p">{`color`}</inlineCode>{` object, instead of only replacing duplicate values from the original theme's color object. If you want to merge sub-values, be sure to use a deep-merging strategy.`}</p>
    <h2>{`Static CSS rendering`}</h2>
    <p>{`If you're rendering React components both server- and client-side, we suggest following `}<a parentName="p" {...{
        "href": "https://www.styled-components.com/docs/advanced#server-side-rendering"
      }}>{`styled-component's server-side rendering instructions`}</a>{` to avoid the flash of unstyled content for server-rendered components.`}</p>
    <h2>{`TypeScript`}</h2>
    <p>{`Uchiru Components includes TypeScript support and ships with its own typings. You will need still need to to install type typings for the peer dependencies if you import those in your own application code.`}</p>
    <p>{`Once installed, you can import components and their prop type interfaces from the `}<inlineCode parentName="p">{`@uchiru/components`}</inlineCode>{` package:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-typescript"
      }}>{`import {BorderBox, BorderBoxProps} from '@uchiru/components'
`}</code></pre>
    <h3>{`Fixing "Duplicate identifier 'FormData'"`}</h3>
    <p>{`Ever since `}<inlineCode parentName="p">{`@types/styled-components`}</inlineCode>{` version `}<inlineCode parentName="p">{`14.1.19`}</inlineCode>{`, it has had a dependency on both `}<inlineCode parentName="p">{`@types/react`}</inlineCode>{` and `}<inlineCode parentName="p">{`@types/react-native`}</inlineCode>{`. Unfortunately, those declarations clash; for more information, see `}<a parentName="p" {...{
        "href": "https://github.com/DefinitelyTyped/DefinitelyTyped/issues/33311"
      }}>{`issue 33311`}</a>{` and `}<a parentName="p" {...{
        "href": "https://github.com/DefinitelyTyped/DefinitelyTyped/issues/33015"
      }}>{`issue 33015`}</a>{` in the DefinitelyTyped repo.`}</p>
    <p>{`You may run into this conflict even if you're not importing anything from `}<inlineCode parentName="p">{`react-native`}</inlineCode>{` or don't have it installed. This is because some package managers hoist packages to the top-level `}<inlineCode parentName="p">{`node_modules`}</inlineCode>{` folder, and the TypeScript compiler automatically includes types from all folders in `}<inlineCode parentName="p">{`node_modules/@types`}</inlineCode>{` by default.`}</p>
    <p>{`The TypeScript compiler allows you to opt-out of this behavior `}<a parentName="p" {...{
        "href": "https://www.typescriptlang.org/docs/handbook/tsconfig-json.html#types-typeroots-and-types"
      }}>{`using the `}<inlineCode parentName="a">{`typeRoots`}</inlineCode>{` and `}<inlineCode parentName="a">{`types`}</inlineCode>{` configuration options`}</a>{`, and the best solution for this error — for now — seems to be to opt out the automatic inclusion of `}<inlineCode parentName="p">{`node_modules/@types`}</inlineCode>{` and instead list the types you want to be included individually.`}</p>
    <p>{`In your `}<inlineCode parentName="p">{`tsconfig.json`}</inlineCode>{`, set the `}<inlineCode parentName="p">{`types`}</inlineCode>{` array under the `}<inlineCode parentName="p">{`compilerOptions`}</inlineCode>{` like so:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-json"
      }}>{`{
  "compilerOptions": {
    "types": ["node", "react", "styled-components", "jest"]
  }
}
`}</code></pre>
    <p>{`Of course, customize the array based on the `}<inlineCode parentName="p">{`@types/`}</inlineCode>{` packages you have installed for your project.`}</p>
    <h2>{`Silencing warnings`}</h2>
    <p>{`Like React, Uchiru Components emits warnings to the JavaScript console under certain conditions, like using deprecated components or props. Similar to React, you can silence these warnings by setting the `}<inlineCode parentName="p">{`NODE_ENV`}</inlineCode>{` environment variable to `}<inlineCode parentName="p">{`production`}</inlineCode>{` during bundling.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      